import React from 'react';
import { Grid } from '@material-ui/core';
import CustomTypography from './CustomTypography.component';

const SeccionTitle = ({ className, children, ...textProps }) => {
  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      className={className}>
      <CustomTypography
          variant='h2'
          customColor='blanco'
          {...textProps}
      >
        {children}
      </CustomTypography>
    </Grid>
  );
};

export default SeccionTitle;
