import { makeStyles } from '@material-ui/core';
import { NEW_PALETTE } from '../../assets/theme';

export default makeStyles((theme) => ({
  sliderContainer: {
    position: 'relative',
    width: '100%',
    margin: 'auto',
    overflow: 'hidden',
  },
  slide: {
    width: '100%',
  },
  image: {
    width: '100%',
    maxHeight: '330px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '500px',
      height: '500px',
    },
  },
  customArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '50%',
    padding: '8px',
    cursor: 'pointer',
    color: NEW_PALETTE.neutral.gray07,
    backgroundColor: NEW_PALETTE.neutral.white,
    '&:hover': {
      color: NEW_PALETTE.neutral.white,
      backgroundColor: 'rgba(216, 190, 255, 0.3)',
    },
    zIndex: 1,
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '20px',
    }
  },
  prevButton: {
    left: '50px',
    [theme.breakpoints.up('xl')]: {
      left: '300px',
    },
  },
  nextButton: {
    right: '50px',
    [theme.breakpoints.up('xl')]: {
      right: '300px',
    },
  },
  dotsContent: {
    position: 'absolute',
    bottom: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
    listStyle: 'none',
    backgroundColor: 'rgba( 0, 0, 0, .8 )',
    width: '88px',
    borderRadius: '24px',
    height: '24px',
    marginLeft: 'calc(50% - 88px / 2)',
    '& .slick-active': {
      color: NEW_PALETTE.primary.main,
    },
    '& li': {
      margin: '0',
      fontSize: '24px',
      fontWeight: '700',
      letterSpacing: '0.03em',
      color: NEW_PALETTE.neutral.gray03,
      cursor: 'pointer',
    },
  },
  infoContainer: {
    position: 'absolute',
    color: NEW_PALETTE.neutral.white,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '580px',
    gap: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '130px',
    [theme.breakpoints.up('xl')]: {
      marginLeft: '430px',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'unset',
      top: '0',
      maxWidth: '100%',
      width: '100vw',
      marginLeft: '0',
    },
    '& .title': {
      fontSize: '29px',
      fontWeight: '600',
      lineHeight: '40px',
      letterSpacing: '0.02em',
      whiteSpace: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '0.01em',
        margin: '1rem',
        marginTop: '2rem',
      }
    },
    '& .action': {
      color: NEW_PALETTE.primary.primary03,
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '1rem',
      },
      '& .arrow': {
        fontSize: '24px',
        fontWeight: '400',
        marginLeft: '8px',
        marginTop: '2px',
      }
    },
  },
}));
