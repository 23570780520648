import React from 'react';
import Image from 'gatsby-image';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowForwardIcon from '@material-ui/icons/KeyboardArrowRight';

const BANNER_BUTTON_ID = {
  'Ver catálogo': 'home-carrusel-catalogo-btn',
  'Cotizar': 'home-carrusel-cotizador-btn',
  'Comprar': 'home-carrusel-comprar-btn',
  'Vender': 'home-carrusel-vender-btn',
  'Ver novedades': 'home-carrusel-blog-btn',
};

const BANNER_IMG_ID = {
  'Ver catálogo': 'home-carrusel-catalogo-imagen',
  'Cotizar': 'home-carrusel-cotizador-imagen',
  'Comprar': 'home-carrusel-comprar-imagen',
  'Vender': 'home-carrusel-vender-imagen',
  'Ver novedades': 'home-carrusel-blog-imagen',
};

const BANNER_TXT_ID = {
  'Ver catálogo': 'home-carrusel-catalogo-txt',
  'Cotizar': 'home-carrusel-cotizador-txt',
  'Comprar': 'home-carrusel-comprar-txt',
  'Vender': 'home-carrusel-vender-txt',
  'Ver novedades': 'home-carrusel-blog-txt',
};

export const CarouselBody = ({ classes, list, sliderSettings, showArrows }) => {
  const slider = React.useRef(null);

  return <div className={classes.sliderContainer}>
    <Slider {...sliderSettings} ref={slider} >
      {list && list.map(( node ) => (
        <div key={node.fileName} className={classes.slide}>
          {node.fluid && (
            <div id={BANNER_IMG_ID[node.actionLabel]}>
              <Image fluid={node.fluid} className={classes.image} />
            </div>
          )}
          <div className={classes.infoContainer} >
            <div className='title' id={BANNER_TXT_ID[node.actionLabel]}>
              {node.title}
            </div>
            <Link to={node.actionLink || '/'} id={BANNER_BUTTON_ID[node.actionLabel]}>
              <div className='action'>
                {node.actionLabel} <span className='arrow'>&gt;</span>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </Slider>

    {showArrows && <>
      <div
        className={`${classes.customArrow} ${classes.prevButton}`}
        onClick={() => slider.current.slickPrev()}
      >
        <ArrowBackIcon />
      </div>
      <div
        className={`${classes.customArrow} ${classes.nextButton}`}
        onClick={() => slider.current.slickNext()}
      >
        <ArrowForwardIcon />
      </div>
    </>}

  </div>;
};
