import React from 'react';
import useStyles from './homeCarousel.style.js';
import { graphql, useStaticQuery } from 'gatsby';
import { parseImagesData } from './parseImageData.js';
import { CarouselBody } from './carouselBody.jsx';

const useGetCarouselImagesMobile = () => {
  const getMobileImages = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/home-carousel/mobile/" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 360, maxHeight: 500, cropFocus: CENTER, fit: COVER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `;
  const { allFile } = useStaticQuery(getMobileImages);
  return parseImagesData(allFile);
};

// se pidió que en Mobile NO funcione el click del paginador
// no hay forma de hacerlo en la configuración del componente de react-slick
const clearDotsAction = dots => {
  const newDots = dots.map( dot => {
    return {
      ...dot,
      props: {
        ...dot.props,
        children: {
          ...dot.props.children,
          props: {
            ...dot.props.children.props,
            onClick: () => {}
          }
        }
      }
    };
  });
  return newDots;
};

const HomeCarouselMobile = () => {
  const list = useGetCarouselImagesMobile();
  const classes = useStyles();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    dotsClass: classes.dotsContent,
    appendDots: dots => (
      <div>{clearDotsAction(dots)}</div>
    ),
    customPaging: i => (
      <div>
        &bull;
      </div>
    )
  };

  return <CarouselBody classes={classes} list={list} sliderSettings={sliderSettings} />;
};

export default HomeCarouselMobile;
