import React from 'react';
import { makeStyles } from '@material-ui/core';
import { BannerCardComponent } from './BannerCard.component';
import { graphql, useStaticQuery } from 'gatsby';

const usesStyle = makeStyles( (theme) => ({
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    maxWidth: '1050px',
    justifyContent: 'center',
    width: '100%',
    marginTop: '48px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 'inherit',
      width: '90%',
      flexDirection: 'column',
      gap: '40px',
    },
  },
}));

export const BuySellOnlineCard = () => {
  const classes = usesStyle();
  const data = useStaticQuery(query);

  return (
    <div className={classes.cardsContainer} >
      <BannerCardComponent
        title='Comprá'
        description='Mirá nuestros autos en stock, financiá tu compra y entregá tu usado.'
        icon={<img src={data.iconCompra.publicURL} alt='icon-compra' />}
        linkText='Ver autos en stock'
        linkTo='/catalogo/'
        id='home-btn-verAutosEnStock-txt'
      />
      <BannerCardComponent
        title='Vendé'
        description='Cotizás tu auto y recibís una oferta al instante.'
        icon={<img src={data.iconVende.publicURL} alt='icon-vende' />}
        linkText='Vender mi usado'
        linkTo='/cotiza/'
        id='home-btn-venderMiUsado-txt'
      />
    </div>
  );
};

const query = graphql`
  query {
    iconCompra: file(sourceInstanceName: { eq: "icons" }, relativePath: { eq: "icon-compra.svg" }) {
      relativePath
      publicURL
    }
    iconVende: file(sourceInstanceName: { eq: "icons" }, relativePath: { eq: "icon-vende.svg" }) {
      relativePath
      publicURL
    }
  }  
`;
