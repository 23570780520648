import React from 'react';
import { makeStyles } from '@material-ui/core';
import { navigate } from 'gatsby';
import { NEW_PALETTE } from '../../assets/theme';

const usesStyle = makeStyles( (theme) => ({
  commonCard: {
    width: '509px',
    height: '224px',
    borderRadius: '32px',
    padding: '24px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      gap: '2rem',
      padding: '1rem',
      height: 'auto',
      paddingBottom: '2rem',
    },
  },
  icon: {
    width: '64px',
    height: '64px',
    backgroundColor: '#EFE5FF',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      marginLeft: '1rem',
    },

  },
  title: {
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '36px',
    letterSpacing: '0.02em',
    color: NEW_PALETTE.neutral.gray07,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '28px',
      letterSpacing: '0.014%',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    color: NEW_PALETTE.neutral.gray06,
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    gap: '2rem',
    [theme.breakpoints.down('sm')]: {
      gap: '3rem',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    marginLeft: '1rem',
    gap: '1rem',
  },
  linkButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '2rem',
  },
  linkButton: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    cursor: 'pointer',
    color: NEW_PALETTE.primary.primaryAction,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  }
}));

export const BannerCardComponent = ({ icon, title, description, linkText, linkTo, id}) => {
  const classes = usesStyle();
  return (
    <div className={classes.commonCard} >
      <div>
        <div className={classes.icon}>
          {icon}
        </div>
      </div>
      <div className={classes.cardInfo}>
        <div className={classes.textContainer} >
          <div className={classes.title} >
            {title}
          </div>
          <div className={classes.description} >
            {description}
          </div>
        </div>
        <div className={classes.linkButtonContainer} >
          <div className={classes.linkButton} onClick={() => navigate(linkTo)} id={id}>
            {linkText} &gt;
          </div>
        </div>
      </div>
    </div>
  );
};
