import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../common/button/Button.component';
import SearchBar from '../common/forStorybook/SearchBar.component';
import { SelectSB } from '../common/forStorybook/SelectSB';
import { NEW_PALETTE } from '../../assets/theme';

const PLACEHOLDER_SEARCH = 'Ingresá marca, modelo o versión';
const LABEL_MARCA = 'Marca';
const LABEL_SEGMENTO = 'Tipo de auto';
const LABEL_MODELO = 'Modelo';
const NO_OPTIONS = 'No hay opciones disponibles';

const useStyles = makeStyles(theme => ({
  pageSearchContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '32px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
    '& .title': {
      fontWeight: '600',
      fontSize: '32px',
      lineHeight: '44px',
      color: NEW_PALETTE.neutral.gray07,
      [theme.breakpoints.down('sm')]: {
        fontWeight: '700',
        fontSize: '26px',
        lineHeight: '36px',
        letterSpacing: '.02em',
      },
    },
    '& .subtitle': {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '2rem',
    '& .searchBar': {
      width: '100%',
      maxWidth: '819px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
  },
  filterOptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    borderRadius: '32px',
    padding: '1.5rem',
    paddingTop: '1rem',
    background: '#F6F6F6',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '90%',
      gap: '2rem',
    },
    '& .filterContainer': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '201px',
      height: '48px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .filterButtonContainer': {
      width: 'auto',
      marginTop: '9px'
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
}));

const HomeSearchLogged = ({
  segments = [],
  loadingSegmentos = false,
  brands = [],
  loadingMarcas = false,
  onSelectMarca = () => {},
  onSelectSegmento = () => {},
  onSearch = () => {},
  models = [],
  loadingModelos = false,
}) => {
  const classes = useStyles();

  const [segment, setSegment] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [ inputSearch, setInputSearch] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    onSearch({ segmento: segment, marca, modelo, keyword: inputSearch, condicion: 0 });
  }

  const onClearMarca = () => {
    onSelectMarca(null);
    setMarca('');
  };

  const onClearModelo = () => {
    setModelo('');
  };

  function handleSelectMarca(sel) {
    setMarca(sel);
    onSelectMarca(sel.value);
    onClearModelo();
  }

  function handleSelectModelo(sel) {
    setModelo(sel);
  }

  function handleSelectSegmento(sel) {
    setSegment(sel);
    onSelectSegmento(sel.label);
    onClearMarca();
    onClearModelo();
  }

  return (
    <div className={classes.pageSearchContainer} >
      <div className={classes.titleContainer}>
        <div className='title' >Explorá todos los modelos</div>
        <div className='subtitle' >
          Encontrá todas las imágenes y características técnicas en nuestro catálogo.
        </div>
      </div>
      <div className={classes.searchContainer}>
        <div className='searchBar' >
          <SearchBar
            autocomplete={false}
            disableClearable={true}
            receivedValue={inputSearch}
            onKeyDownInput={v => {
              if (v.keyCode === 13 && inputSearch.trim().length > 0) {
                handleSubmit(v);
              }
            }}
            onChangeInput={v => {
              setInputSearch(v.target.value);
            }}
            placeholder={PLACEHOLDER_SEARCH}
            id="home-search-buscadorGeneral"
            noOptionsText={''}
          />
        </div>
        <div className={classes.filterOptionsContainer} >
          <div className='filterContainer' >
            <SelectSB
              id='select-segmento'
              label={LABEL_SEGMENTO}
              loading={loadingSegmentos}
              onChange={handleSelectSegmento}
              value={segment}
              noOptionsText={NO_OPTIONS}
              options={segments.map((seg) => ({
                label: seg.name,
                value: seg.type,
              }))}
            />
          </div>
          <div className='filterContainer' >
            <SelectSB
              id='select-marca'
              label={LABEL_MARCA}
              loading={loadingMarcas}
              value={marca}
              noOptionsText={NO_OPTIONS}
              onChange={handleSelectMarca}
              options={brands.map((b) => ({
                label: b.name,
                value: b.id[0]
              }))} />
          </div>
          <div className='filterContainer' >
            <SelectSB
              id='select-modelo'
              label={LABEL_MODELO}
              disabled={!marca}
              loading={loadingModelos}
              onChange={handleSelectModelo}
              value={modelo}
              noOptionsText={NO_OPTIONS}
              options={models.map((mod) => ({
                label: mod.name,
                value: mod.id[0]
              }))} />
          </div>
          <div className='filterButtonContainer' >
            <Button
              classNameProps={classes.button}
              data-test-id="search-button"
              id="home-btn-buscar-background"
              textId="home-btn-buscar-txt"
              onClick={handleSubmit}
            >
                Buscar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSearchLogged;
