import React from 'react';
import useStyles from './homeCarousel.style.js';
import { graphql, useStaticQuery } from 'gatsby';
import { parseImagesData } from './parseImageData.js';
import { CarouselBody } from './carouselBody.jsx';

const useGetCarouselImagesDesk = () => {
  const getDesktopImages = graphql`
    query {
      allFile(filter: { relativePath: { regex: "/home-carousel/desk/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1306, maxHeight: 315, cropFocus: CENTER, fit: COVER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    `;
  const { allFile } = useStaticQuery(getDesktopImages);
  return parseImagesData(allFile);
};

const HomeCarouselDesk = () => {
  const list = useGetCarouselImagesDesk();
  const classes = useStyles();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    dotsClass: classes.dotsContent,
    focusOnSelect: false,
    appendDots: dots => (
      <div>
        {dots}
      </div>
    ),
    customPaging: () => (
      <div>
        &bull;
      </div>
    )
  };

  return <CarouselBody classes={classes} list={list} sliderSettings={sliderSettings} showArrows />;
};

export default HomeCarouselDesk;
