import React from 'react';
import Button from '../common/button/Button.component';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Image from 'gatsby-image';
import { NEW_PALETTE } from '../../assets/theme';

const usesStyle = makeStyles( (theme) => ({
  mainContainer: ({ backgroundColor }) => ({
    backgroundColor,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }),
  contentContainer: {
    width: '100%',
    maxWidth: '1050px',
    display: 'flex',
    flexDirection: ({ contentDirection }) => contentDirection || 'row',
    justifyContent: ({ contentDirection }) => contentDirection === 'column' ? 'center' : 'space-between',
    alignItems: 'center',
    height: ({ height }) => height.desktop,
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      flexDirection: 'column',
      justifyContent: 'center',
      height: ({ height }) => height.mobile,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    maxWidth: '510px',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      textAlign: 'center',
      gap: '40px',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '29px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
    color: NEW_PALETTE.neutral.gray07,
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.01em',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    padding: 0,
    margin: 0,
    marginTop: '-10px',
    color: NEW_PALETTE.neutral.gray06,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      fontSize: '14px',
      lineHeight: '20px',
    },

  },
  btn:{
    width: '210px',
    height: '48px',
    marginTop: '16px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  }
}));

const Banner = ({
  photoDesktop,
  photoMobile,
  title,
  description,
  btnTxt,
  onClick,
  backgroundColor = 'transparent',
  height = 'auto',
  content,
  contentDirection,
  btnId,
  btnTextId,
}) => {
  const classes = usesStyle({ height, backgroundColor, contentDirection });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.mainContainer} >
      <div className={classes.contentContainer} >

        <div className={classes.textContainer} >

          <div className={classes.title} >
            {title}
          </div>

          <div className={classes.description} >
            {description}
          </div>

          {isMobile && photoMobile && <div style={{
            width: '100%',
            maxWidth: '360px',
            height: 'auto',
            margin: '0 auto',
          }} >
            <Image fluid={photoMobile} id='imageMobile'></Image>
          </div>}

          {btnTxt && <Button onClick={() => onClick()} classNameProps={classes.btn} id={btnId} textId={btnTextId}>
            {btnTxt}
          </Button>}

        </div>

        {content}

        {!isMobile && photoDesktop && <div style={{
          width: '100%',
          maxWidth: '380px',
          height: 'auto',
          maxHeight: '319px',
        }} >
          <Image fluid={photoDesktop} id='imageDesktop'></Image>
        </div>}
      </div>
    </div>
  );
};

export default Banner;
