/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Box } from '@material-ui/core';
import Image from '../common/Image.component';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CustomTypography from '../common/CustomTypography.component';

const useStyles = makeStyles((theme) => ({
  container: {
    gap: '16px',
    display:'flex',
    flexDirection:'column',
    [theme.breakpoints.up('md')]:{
      marginRight:theme.spacing(2),
    },
  },
  image: {
    width: '100%',
    paddingTop: '56.6%',
    borderRadius: '0.5rem'
  },
  link: {
    width: '30%',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
    }
  }
}));

export default function Post({ featuredImageUrl, title, excerpt, date, category, link }) {
  const classes = useStyles();
  return (
    <a target="_blank" href={link} className={classes.link}>
      <Box className={clsx('post', classes.container)} >
        <Image image={featuredImageUrl} height={265} className={clsx('image', classes.image)} />
        <CustomTypography
          variant='caption1'
          customColor='tertiary'
          className='category'
        >
          {category}
        </CustomTypography>
        <CustomTypography
          variant='subtitle1'
          customColor='blanco'
          className='title'
        >
          {title}
        </CustomTypography>
        <CustomTypography
          variant='body1'
          customColor='blanco'
          className='excerpt'
        >
          <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
        </CustomTypography>
        <Box>
          <CustomTypography
            variant='caption2'
            customColor='blanco'
            className='date'
          >
            {date}
          </CustomTypography>
        </Box>
      </Box>
    </a>
  );
}
