import React from 'react';
import { makeStyles } from '@material-ui/core';
import Banner from '../common/Banner.component';
import { graphql, useStaticQuery } from 'gatsby';
import { BuySellOnlineCard } from './BuySellOnlineCard';
import { NEW_PALETTE } from '../../assets/theme';

const QUOTATION_TITLE = 'Cotizá';
const QUOTATION_BTN_TEXT = 'Cotizar';
const QUOTATION_DESCRIPTION =
  'Cargas los datos del auto, y descubrí su valor con nuestro cotizador exclusivo. ¡Simple y rápido!';

const usesStyle = makeStyles((theme) => ({
  rareUnderlineType: {
    textDecoration: `underline ${theme.palette.tertiary.background}`,
    textDecorationThickness: '0.75rem',
    textUnderlineOffset: '-0.3125rem',
    textDecorationSkipInk: 'none',
    '-webkit-text-decoration-line': 'underline',
    textDecorationColor: theme.palette.tertiary.background
  },
  container: {
    width: '100%',
    marginTop: '48px',
  },
}));

const query = graphql`
query {
  cotizadorHomeImgMobile: file(relativePath: { eq: "cotizador-home-desktop.png" }) {
    childImageSharp {
      fluid(maxWidth: 310, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  cotizadorHomeImgDesktop: file(
    relativePath: { eq: "cotizador-home-desktop.png" }
  ) {
    childImageSharp {
      fluid(maxWidth: 350, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`;

const HomeBanner = ({ onQuotationClick }) => {
  const data = useStaticQuery(query);
  const classes = usesStyle();
  return (
    <div className={classes.container}>
      <Banner
        title={QUOTATION_TITLE}
        description={QUOTATION_DESCRIPTION}
        btnTxt={QUOTATION_BTN_TEXT}
        btnId='home-btn-cotizar-background' 
        btnTextId='home-btn-cotizar-txt'
        photoDesktop={data.cotizadorHomeImgDesktop.childImageSharp.fluid}
        photoMobile={data.cotizadorHomeImgMobile.childImageSharp.fluid}
        onClick={onQuotationClick}
        backgroundColor={NEW_PALETTE.primary.primary02}
        height={{ mobile: '683px', desktop: '479px' }}
      />
      <Banner
        title={<>
          <span className={classes.rareUnderlineType}>Comprá y vendé</span> tu auto online
        </>}
        content={<BuySellOnlineCard />}
        height={{ mobile: '908px', desktop: '488px' }}
        backgroundColor={NEW_PALETTE.primary.primary01}
        contentDirection='column'
      />
    </div>
  );
};

export default HomeBanner;

