
// [nombre_de_imagen]: {...}
const bannerActions = {
  catalogo: {
    title: 'Descubrí en nuestro catálogo de autos la información más completa',
    actionLabel: 'Ver catálogo',
    actionLink: '/catalogo-autos/',
    sort: 1,
  },
  cotizar: {
    title: 'Conocé el valor de tu usado en el mercado',
    actionLabel: 'Cotizar',
    actionLink: '/cotizador-autos/', 
    sort: 2,
  },
  comprar: {
    title: 'Conocé los autos que vendemos en Carmuv',
    actionLabel: 'Comprar',
    actionLink: '/catalogo/',
    sort: 3,
  },
  vender: {
    title: 'Cotizá y vendé tu auto de forma rápida y simple',
    actionLabel: 'Vender',
    actionLink: '/cotiza/',
    sort: 4,
  },
  novedades: {
    title: 'Encontrá las últimas novedades del mundo automotriz',
    actionLabel: 'Ver novedades',
    actionLink: 'https://blog.carmuv.com.ar/',
    sort: 5,
  },
};

export const parseImagesData = (allFile) => {
  return allFile.edges.map(({ node }) => {
    return {
      fluid: node.childImageSharp.fluid,
      fileName: node.relativePath.split('/').pop().split('.')[0],
    };
  }).reduce( (acc, item) => {
    return [...acc, { ...bannerActions[item.fileName], fluid: item.fluid }];
  },[]).sort((a, b) => a.sort - b.sort);
};
