import React from 'react';
import Layout from '../components/layouts/layout.component';
import HomeSearchContainer from '../containers/home-search/homeSearch.container';
import { goToCatalogAutosList, goToMainCotizador } from '../api/navigation';
import  HomeBanner  from '../components/home-banner/HomeBanner.component';
import { graphql } from 'gatsby';
import LastNews from '../components/LastNews/LastNews.component';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import HomeCarouselMobile from '../components/home-carousel/homeCarouselMobile.component';
import HomeCarouselDesk from '../components/home-carousel/homeCarouselDesk.component';

export const query = graphql`
query GetLastPosts {
  allWpPost(limit: 3) {
    nodes {
      date(locale: "es-ar", formatString: "d \\d\\e MMMM, YYYY")
      slug
      title
      link
      excerpt
      featuredImage {
        node {
          mediaDetails {
            sizes {
              sourceUrl
              name
            }
          }
          sizes
        }
      }
      categories {
        nodes {
          name
        }
      }
    }
  }
}`;

// eslint-disable-next-line max-len
const META_DESCRIPTION = 'Mejorá tu negocio automotor con Carmuv, la herramienta digital especializada en vehículos que está revolucionando el rubro. ¡Descubrila!';
const TITLE = 'Inicio - Carmuv';

const IndexPage = ({ data, location }) => {
  const posts = data.allWpPost.nodes.map(post => ({
    featuredImageUrl: post.featuredImage?.node.mediaDetails.sizes.find(size => size.name === 'medium').sourceUrl,
    title: post.title,
    excerpt: post.excerpt,
    date: post.date,
    category: post.categories.nodes[0].name,
    link: `${process.env.GATSBY_BLOG_URL}${post.link}`
  }));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Layout title={TITLE} location={location} description={META_DESCRIPTION}>
      <div style={{ height: '3.5rem', width: '100%' }} >{
        /* TODO: usar stick en lugar de fixed en el menu */
        /* quitar el margen superior de 3.5rem en las otras páginas */
      }
      </div>
      {isMobile ? <HomeCarouselMobile /> : <HomeCarouselDesk />}
      <HomeSearchContainer onSearch={goToCatalogAutosList} />
      <HomeBanner
        onQuotationClick={goToMainCotizador}
      />
      <LastNews news={posts} />
    </Layout>
  );
};

export default IndexPage;
