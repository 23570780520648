import React from 'react';
import { Box } from '@material-ui/core';
import Post from './Post.component';
import { makeStyles } from '@material-ui/core/styles';
import SeccionTitle from '../common/SeccionTitle.component';

const TITLE = 'Últimas novedades, reviews, ranking y más…';

const useStyles = makeStyles((theme) => ({
  mainContainer:{
    backgroundColor: theme.palette.grayScale.g500,
    [theme.breakpoints.down('sm')] : {
      padding: '4rem 1.5rem 4rem 1.5rem',
    },
    [theme.breakpoints.up('md')] : {
      padding: '5.5rem 3.1875rem'
    },
    borderBottom: `0.0625rem solid ${theme.palette.grayScale.g400}`
  },
  titleContainer:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(5.25),
  },
  section: {
    textAlign: 'center',
  },
  newsContainer: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      '& a:first-child .post': {
        marginBottom: '2rem'
      },
      '& a:not(:first-child) .post': {
        flexDirection: 'row',
        height: 'fit-content',
        marginBottom: '1rem'
      },
      '& a:not(:first-child) .category, a:not(:first-child) .excerpt, a:not(:first-child) .date': {
        display: 'none'
      },
      '& a:not(:first-child) .image': {
        minHeight: 100,
        paddingTop:'0',
        backgroundSize:'contain',
        backgroundPosition: '50% 20%',
        flexBasis: '40%',
      },
      '& a:not(:first-child) .title': {
        flexBasis: '60%',
        fontSize: theme.typography.size.medium,
      }
    }
  },
}));

const LastNews = ({ news }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.titleContainer}>
        <SeccionTitle className={classes.section}>{TITLE}</SeccionTitle>
      </Box>
      <Box className={classes.newsContainer}>
        <Posts news={news}/>
      </Box>
    </Box>
  );
};

const Posts = ({ news }) => {
  return news.map(
    (post) =>
      <Post key={post.link} {...post} />
  );
};

export default LastNews;
