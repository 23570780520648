import React, { useState } from 'react';
import HomeSearchLogged from '../../components/homeSearchLogged/HomeSearchLogged.component';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_SEGMENTOS,
  GET_MODELS,
  GET_BRANDS,
} from '../../queries';

export default function HomeSearchContainer({ onSearch }) {
  const [marca, setMarca ] = useState();
  const [segmento, setSegmento ] = useState();

  const handleEnterKeyPress = (event) => event.charCode === 13;

  const { data: segmentData, loading: loadingSegmentos } = useQuery(GET_SEGMENTOS,
    {
      variables: { all: true }
    });
  const { data: brandData, loading: loadingMarcas } = useQuery(GET_BRANDS,
    {
      variables: { segment: segmento }
    });
  const { data: modelData, loading: loadingModelos } = useQuery(GET_MODELS, {
    variables: { brandId: marca, segment: segmento }
  });

  const segments = segmentData?.segments || [];
  const brands = brandData?.brands || [];
  const models = modelData?.models || [];

  return (
    <HomeSearchLogged
      data-test-id="search"
      segments={segments}
      loadingSegmentos={loadingSegmentos}
      brands={brands}
      loadingMarcas={loadingMarcas}
      models={models}
      loadingModelos={loadingModelos}
      loadingKeywords={false}
      onSelectMarca={(value) => setMarca(value)}
      onSelectModelo={(value) => setModelo(value)}
      onSelectSegmento={(value) => setSegmento(value)}
      tabValue={0}
      onSearch={onSearch}
      handleEnterKeyPress={handleEnterKeyPress}
    />);
}
